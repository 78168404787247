import { FC } from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Grid, IconButton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { MenuLogo, MobileMenuLogo } from 'src/components/Logo';
import { AccountPopover } from 'src/components/navbar/AccountPopover';
import { LanguagePopover } from 'src/components/navbar/LanguagePopover';
import { NotificationPopup } from 'src/components/navbar/NotificationPopup';
import { SupportMenu } from 'src/components/navbar/SupportMenu';
import { useAppContext } from 'src/contexts/AppContext';
import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import { useMobile } from 'src/hooks/useMobile';
import { TenantsDropdown } from 'src/tenants';
import TourAvailableButton from 'src/tours/tourAvailableButton';
import useWindowSize from 'src/utils/useWindowSize';
import { TopNavigationBarModal } from 'src/videoModals';

import InformationSupport from './informationSupport';
import ListManagerButton from './listManagerButton';

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '64px',
    display: 'flex',
    zIndex: 1201,

    '&.mobile': {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
}));

const VerticalSpacer = styled(Box)({
    width: '16px',

    '&.mobile': {
        width: '8px',
    },
});

const Spacer = styled('div')(() => ({
    flexGrow: 1,
}));

export const Navbar: FC = () => {
    const { toggleSidebar } = useAppContext();
    const isMobile = useMobile();
    const windowSize = useWindowSize();
    const theme = useTheme();
    const isSupplier = useIsUserSupplier();

    return (
        <Root className={isMobile && 'mobile'}>
            <IconButton color="primary" onClick={toggleSidebar}>
                <MenuIcon fontSize="small" />
            </IconButton>
            <VerticalSpacer />
            <Link to="/dashboard">
                {windowSize.width <= theme.breakpoints.values.sm ? (
                    <MobileMenuLogo />
                ) : (
                    <MenuLogo />
                )}
            </Link>
            {window.ENABLE_TENANT_DROPDOWN && <TenantsDropdown sx={{ ml: 4 }} />}
            <TourAvailableButton sx={{ ml: 4, minWidth: '120px' }} />
            <SupportMenu />
            <VerticalSpacer className={isMobile && 'mobile'} />
            <InformationSupport />
            <Grid display="flex" sx={{ pt: 0.5, pb: 0.5 }}>
                <TopNavigationBarModal />
            </Grid>
            {!isSupplier && <ListManagerButton />}
            <LanguagePopover />
            <VerticalSpacer className={isMobile && 'mobile'} />
            <NotificationPopup />
            <VerticalSpacer className={isMobile && 'mobile'} />
            <AccountPopover />
        </Root>
    );
};
