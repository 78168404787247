import { AxiosResponse } from 'axios';
import { LoaderFunction } from 'react-router';

import { jwtPayload } from 'src/utils/login';

class SessionManager {
    private REFRESH_TOKEN_KEY: string = 'jwtRefreshToken';

    private ACCESS_TOKEN_KEY: string = 'jwtAccessToken';

    tenants: Array<number> = [];

    private accessToken: string = null;

    private refreshToken: string = null;

    private switchTenantsPromise: Promise<void> | null = null;

    constructor() {
        this.load();
    }

    // eslint-disable-next-line
    async switchToCrossTenants(withReload: boolean = true) {
        // await refreshCall(this.getRefreshToken()).then((data) => {
        //     this.setAccessToken(data.accessToken);
        // });
        // if (withReload) {
        //     window.location.reload();
        // }
    }
    // eslint-disable-next-line
    async switchTenant(tenantId: number) {
        // await refreshCall(this.getRefreshToken(), tenantId).then((data) => {
        //     this.setAccessToken(data.accessToken);
        // });
        // window.location.href = window.DependencyInjector.router.getCurrentFullUrl();
    }

    /* Getters Setters */
    getAccessToken() {
        if (!this.accessToken) {
            this.load();
        }
        return this.accessToken;
    }

    setAccessToken(token: string) {
        this.accessToken = token;
        this.save();
    }

    getRefreshToken() {
        if (!this.refreshToken) {
            this.load();
        }
        return this.refreshToken;
    }

    setRefreshToken(token: string) {
        this.refreshToken = token;
        this.save();
    }

    /* Load Save */
    load() {
        this.refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        this.accessToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }

    save() {
        if (this.refreshToken === null || this.refreshToken === undefined) {
            localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        } else {
            localStorage.setItem(this.REFRESH_TOKEN_KEY, this.refreshToken);
        }
        if (this.accessToken === null || this.accessToken === undefined) {
            localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        } else {
            localStorage.setItem(this.ACCESS_TOKEN_KEY, this.accessToken);
        }
    }

    logout() {
        this.tenants = [];
        this.accessToken = null;
        this.refreshToken = null;
        this.save();
    }

    isLoggedIn(): boolean {
        return this.accessToken !== null;
    }

    getCurrentTenantId(): number {
        const { active_tenant_id: activeTenantId } =
            SessionManager.getTokenPayload(this.getAccessToken()) || {};
        return activeTenantId;
    }

    getCrossTenants(): number[] {
        const { cross_tenant_ids: crossTenantIds } = SessionManager.getTokenPayload(
            this.getAccessToken(),
        );
        return crossTenantIds ?? [];
    }

    createLoader<T>(loader: LoaderFunction<T>): LoaderFunction<T> {
        if (this.switchTenantsPromise === null) {
            this.switchTenantsPromise = this.checkForTenantSwitching();
        }
        return async (...args) => {
            await this.switchTenantsPromise;
            this.switchTenantsPromise = null;
            return loader(...args);
        };
    }

    checkForTenantSwitching(): Promise<void> {
        if (!this.getAccessToken()) return Promise.resolve();
        const currentTenantID = this.getCurrentTenantId();

        const { searchParams } = new URL(window.location.href);

        if (!searchParams.get('tenantID')) {
            return this.switchToCrossTenants(false);
        }
        if (searchParams.get('tenantID') !== currentTenantID?.toString()) {
            return this.switchTenant(Number(searchParams.get('tenantID')));
        }
        return Promise.resolve();
    }

    static getTokenPayload(token: string) {
        return jwtPayload(token);
    }
}

export const inferredTenantRedirect = async (headers, url = '') => {
    if (headers['x-tenant-id']) {
        console.log('Detected tenant id: ', headers['x-tenant-id'], ' from ', url);
        try {
            const tenantId = parseInt(headers['x-tenant-id'], 10);
            let currentUrl;
            if (tenantId) {
                currentUrl = new URL(window.DependencyInjector.router.getCurrentFullUrl());
                const { searchParams } = currentUrl;
                if (!searchParams.has('tenantID')) {
                    searchParams.append('tenantID', tenantId.toString());
                } else if (searchParams.tenantID !== tenantId.toString()) {
                    searchParams.set('tenantID', tenantId.toString());
                }
            }

            if (tenantId !== window.SessionManager.getCurrentTenantId()) {
                await window.SessionManager.switchTenant(tenantId);
                // window.location.href = currentUrl;
            }
        } catch (error) {
            console.error('Error parsing tenant id: ', error);
        }
    }
};

export const inferredTenantInterceptor = async (response: AxiosResponse) => {
    await inferredTenantRedirect(response.headers, response.request.responseURL);
    return response;
};

declare global {
    interface Window {
        SessionManager: SessionManager;
    }
}
window.SessionManager = new SessionManager();
export default window.SessionManager;
