import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { Avatar, Box, MenuItem, Select } from '@mui/material';

import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import SessionManager from 'src/login/sessionManager';
import { useTenantsData } from 'src/redux/tenants/hook';

const CROSS_TENANTS_ID = 'cross-tenants';

const TenantsDropdown = ({ ...rest }) => {
    const activeTenantId = SessionManager.getCurrentTenantId();
    const [selectedTenant, setSelectedTenant] = useState<number | string>(activeTenantId);
    const tenants = useTenantsData();
    const isVendor = useIsUserSupplier();

    const handleChange = (event) => {
        if (event.target.value === CROSS_TENANTS_ID) {
            SessionManager.switchToCrossTenants();
        } else {
            SessionManager.switchTenant(event.target.value);
        }
        setSelectedTenant(event.target.value);
    };

    return (
        <Select
            {...rest}
            value={selectedTenant ?? CROSS_TENANTS_ID}
            onChange={handleChange}
            displayEmpty
            disabled={isVendor || tenants.length === 1}
            IconComponent={tenants.length === 1 ? () => null : undefined}
        >
            {(tenants || []).map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>{tenant?.name}</span>
                        {tenant?.logo && (
                            <Avatar
                                src={tenant?.logo}
                                alt={`${tenant?.name} logo`}
                                sx={{ width: 24, height: 24, marginLeft: 1 }}
                            />
                        )}
                    </Box>
                </MenuItem>
            ))}
            {tenants.length > 1 && (
                <MenuItem value={CROSS_TENANTS_ID}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Trans>
                            <span>All Tenants</span>
                        </Trans>
                    </Box>
                </MenuItem>
            )}
        </Select>
    );
};

export default TenantsDropdown;
